import { graphql } from "gatsby"
import * as React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import {
  RenderComponent,
  RenderProductCarousel,
} from "../components/components"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data }) => {
  const pageData = data.allNodeWorkWithUs.nodes[0]
  const components = pageData.relationships.field_components
  const products = data.allNodeProduct.nodes

  return (
    <Layout>
      <Seo
        title={pageData.field_meta_title}
        description={pageData.field_meta_description}
      />

      {/* Hero Block */}
      <ImageTextBlock
        height="100"
        className="hero"
        image={pageData.relationships.field_image}
        image_position="left"
        slanted
      >
        <Heading size="xxl" spacing="l">
          {pageData.title}
        </Heading>
        <Paragraph>
          <span dangerouslySetInnerHTML={{ __html: pageData.body.processed }} />
        </Paragraph>
      </ImageTextBlock>

      {/* Zigzag */}

      {components?.map((component, index) =>
        component.internal.type === "paragraph__product_list"
          ? RenderProductCarousel(component, index, products)
          : RenderComponent(component, index)
      )}
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNodeWorkWithUs(
      filter: { langcode: { eq: $language }, status: { eq: true } }
    ) {
      nodes {
        title
        path {
          alias
        }
        body {
          processed
        }
        field_meta_description
        field_meta_title
        relationships {
          field_image {
            ...imageFragment
          }
          field_components {
            ... on Node {
              ...contentList
              ...imageTextFragment
              ...productList
              ...standout
              ...videoFragment
            }
          }
        }
      }
    }
    allNodeProduct(
      filter: { langcode: { eq: $language }, status: { eq: true } }
      sort: { fields: field_weight, order: ASC }
    ) {
      nodes {
        title
        field_short_description
        path {
          alias
          langcode
        }
        relationships {
          field_main_color {
            name
          }
          field_image {
            ...imageFragment
          }
        }
      }
      totalCount
    }
  }

  fragment standout on paragraph__standout {
    internal {
      type
    }
    field_title
    field_content {
      processed
    }
    relationships {
      field_background_color {
        name
      }
      field_cta {
        ...ctaFragment
      }
    }
  }

  fragment contentList on paragraph__content_list {
    internal {
      type
    }
    field_columns {
      processed
    }
    field_content {
      processed
    }
    field_title
    internal {
      type
    }
    field_outro {
      processed
    }
    relationships {
      field_background_color {
        name
      }
      field_image {
        ...imageFragment
      }
    }
    field_show_number
  }

  fragment productList on paragraph__product_list {
    field_title_product_list
    field_intro_product_list {
      processed
    }
    internal {
      type
    }
  }
`
